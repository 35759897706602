import React from "react";

const RefundPolicy = () => {
  return (
    <div className="refund-policy-container">
      <h1>Reschedule policy</h1>
      <p>
        Rescheduling is allowed 2 Hours prior to slot time. Rescheduling of
        booking can be only done once. Once rescheduled, booking cannot be
        cancelled.
      </p>
      <h1>Cancellation policy</h1>
      <p>
        {`0-4 hrs prior to slot: Cancellations not allowed. >4 hrs prior to slot:
        15.0% of Gross Amount will be deducted as cancellation fee.`}
      </p>
    </div>
  );
};

export default RefundPolicy;
